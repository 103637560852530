<template>
  <div class="keysbox" :class="{ 'mb-keysbox': isMinDevice }">
    <!--在线检测 -->
    <div class="center">
      <div class="commercial" :class="{ 'mb-commercial': isMinDevice }">
        <img class="w100" :src="onlineUrl + finalFuncImgs[4]" alt="" />
      </div>
      <div
        class="commercial commercial-text"
        :class="{ 'mb-commercial-text': isMinDevice }">
        The detected key will be encrypted. We hate platforms that steal keys
        and charge high fees. VIPs can receive the detection source code for
        free.
      </div>
      <div class="main" :class="{ 'mb-main': isMinDevice }">
        <div class="box box1">
          <div class="flex-s box-title">
            <div class="title">On-Line Checking</div>
            <Cselect
              v-if="!isMinDevice"
              :width="3.2"
              :height="0.5"
              :selectList="selectListType"
              v-model="selectItemType" />
            <Cselect
              v-if="isMinDevice"
              :width="8.1413"
              :height="1.1265"
              :selectList="selectListType"
              v-model="selectItemType" />
          </div>
          <div class="infobox">
            <textarea
              placeholder=""
              class="infobox-box"
              name=""
              id=""
              cols="30"
              rows="10"
              v-model="codevalue">
            </textarea>
          </div>
          <div
            class="l_btnbox"
            style="margin-bottom: 0.3rem"
            :class="{ 'flex-b': isMinDevice }">
            <div style="position: relative">
              <div
                v-if="!isMinDevice"
                @click="handleImportExcel"
                class="btn1 point">
                Import
              </div>
              <div v-if="!isMinDevice" class="point btn3" @click="modelExport">
                Batch detection template download
              </div>
            </div>
            <div class="btn1 point" @click="beforedocheck">Start Check</div>
          </div>
        </div>
        <div class="box box2">
          <div class="title box-title">
            <div class="title">Check Results</div>
          </div>
          <div
            class="infobox showinfobox"
            v-loading="loading"
            :element-loading-text="loadingText"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(240, 240, 240, 0.7)">
            <div v-if="this.parseCheckvluered.length > 0">
              <div id="copyText">
                <div
                  v-for="(item, index) in parseCheckvluered"
                  :key="index"
                  style="margin-top: 0.12rem">
                  <div>Key: {{ item.Key }}</div>
                  <div>Description: {{ item.Description }}</div>
                  <div>Version: {{ item.Version }}</div>
                  <div style="color: red">
                    Result:
                    {{
                      selectList.find(
                        (vItem) => item.status?.indexOf(vItem.value) > -1
                      )?.label
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="r_btnbox" :class="{ 'mb-r_btnbox': isMinDevice }">
            <!-- <Cselect class="btn3" :width="2.4" :height="0.4" :selectList="selectList" v-model="selectItem" /> -->
            <Cselect
              class="btn3"
              v-if="!isMinDevice"
              :width="2.4"
              :height="0.4"
              :selectList="selectList"
              v-model="selectItem" />
            <Cselect
              class="btn3"
              v-if="isMinDevice"
              :width="5.1413"
              :height="1.1265"
              :selectList="selectList"
              v-model="selectItem" />
            <div
              v-if="!isMinDevice"
              @click="exportExcelOnClick"
              class="btn4 point flex-c">
              Export
            </div>
            <div
              v-if="isMinDevice"
              @click="exportExcelOnClick"
              class="btn4 point flex-c"
              :class="{ 'mb-btn4': isMinDevice }">
              Copy
            </div>
          </div>
        </div>
      </div>
      <!-- 热门推荐 -->

      <div v-if="goodsList.length" class="goods-promotion">
        <div class="goods-list">
          <goodListCard :goodsLists="goodsList" :titleName="titleName" />
        </div>
        <!-- <div  class="goods-list">
          <div @click="lastPageOnClick" class="left-icon flex-c">
            <div class="img" :class="{ 'rotate-img': page > 1 }"></div>
          </div>
          <div class="goodsboxlist">
            <div
              @click="
                $router.push({ path: '/MallDetail', query: { id: item.id } })
              "
              v-for="item in goodsList.slice(0, 4)"
              :key="item.id"
              class="goodsbox point"
            >
              <div class="imgbox">
                <img class="w100 h100" :src="item.image" alt="" />
              </div>
              <div class="info">
                {{ item.title }}
              </div>
              <div class="price">
                <div class="bean">${{ item.marketprice }}</div>
                <div class="bottom-vip-price vipbean">
                  ${{ item.price }}
                  <div class="img">
                    <img
                      class="w100 h100"
                      src="@/assets/images/pc/pc-home-icon12.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
              <div class="btnbox flex-b point">
                <div @click.stop="addToCarOnClick(item)" class="check point">
                  ADD TO CART
                </div>
              </div>
            </div>
          </div>
          <div @click="nextPageOnClick" class="right-icon flex-c">
            <div class="img" :class="{ 'rotate-img': TotalPages > page }"></div>
          </div>
        </div> -->
      </div>
    </div>
    <van-overlay :show="checkTipsShow">
      <div
        class="check-tip-box fixed-c"
        :class="{ 'mb-check-tip-box': isMinDevice }">
        <div class="check-tip-box-top flex-c">Tips</div>
        <div class="check-tip-box-content">
          <div class="content-text">
            The detected key will be encrypted. We hate platforms that steal
            keys and charge high fees. VIPs can receive the detection source
            code for free.
          </div>
          <div class="content-btn-box flex-b">
            <div class="btn1 flex-c point" @click="closeOnClick(true)">
              No more reminders
            </div>
            <div class="btn1 flex-c point" @click="closeOnClick(false)">OK</div>
          </div>
          <div class="close-btn point" @click="closeOnClick(false)">
            <img
              class="w100 h100"
              src="@/assets/images/pc/pc-home-icon10.png"
              alt=""
              srcset="" />
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { checkKeyApi, getHotListApi, addCarApi } from "../../network/api";
import {
  exportExcel,
  selectFileAsArrayBuffer,
  excel2Records,
} from "@/utils/excel.js";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import _ from "lodash";
export default {
  data() {
    return {
      selectItem: "all",
      selectItemType: "redeemms",
      codevalue: "",
      checkvluered: [],
      goodsList: [],
      page: 1,
      TotalPages: 1,
      loading: false,
      loadingText: "loading1%",
      checkTipsShow: false,
      Timer: null,
      tipdata: false, //是否一直弹窗
      titleName: "Related Products",
    };
  },
  computed: {
    ...mapGetters(["isVip", "isLogin", "finalFuncImgs"]),
    ...mapState(["homeGoods", "onlineUrl", "isMinDevice"]),
    mbGoodsList() {
      console.log(33331, _.chunk(this.goodsList, [2]));
      return _.chunk(this.goodsList, [2]);
    },
    selectList() {
      let list = [
        {
          label: "all",
          value: "all",
        },
        {
          label: "Blocked key",
          value: "crapped",
        },
        {
          label: "Active",
          value: "ctive",
        },
        {
          label: "Used keys",
          value: "edeemed",
        },
      ];
      if (this.selectItemType === "pid") {
        list = [
          {
            label: "all",
            value: "all",
          },
          {
            label: "phone key",
            value: "008",
          },
          {
            label: "Fail key",
            value: "060",
          },
          {
            label: "Blocked key",
            value: "003",
          },
          {
            label: "Online key",
            value: "online",
          },
        ];
      }
      return list;
    },
    selectListType() {
      return ["redeemms", "pid"];
    },
    parseCheckvluered() {
      let list = this.checkvluered;
      if (this.selectItem === "all") {
        return list;
      }
      list = this.checkvluered.filter((item) => {
        if ((item?.status || "").indexOf(this.selectItem) > -1) {
          return true;
        }
        return false;
      });
      return list;
    },
  },
  created() {
    this.init();
    this.tipdata = localStorage.getItem("isShowTip");
  },
  methods: {
    ...mapActions(["getCartList"]),
    ...mapMutations(["setShowTipPopup", "setShowTopCart"]),
    init() {
      this.getHotList();
    },
    async addToCarOnClick(item, isNum) {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      if (!this.isVip) {
        this.$message.error(
          "Please contact customer service to obtain VIP for free"
        );
        return false;
      }
      clearTimeout(this.Timer);
      const params = {
        goods_id: item.id,
        nums: isNum ? this.goodNum : 1,
      };
      try {
        const res = await addCarApi(params);
        this.$message.success("Added successfully");
        await this.getCartList();
        this.setShowTopCart(true);
      } catch (error) {
        this.setShowTipPopup(true);
      }
    },
    closeOnClick(notShowTip) {
      if (notShowTip) {
        localStorage.setItem("isShowTip", true);
        this.tipdata = true;
      }
      this.checkTipsShow = false;
      this.docheck();
      // if (notShowTip) {
      //   this.$globelData.isShowTip = false;
      // }
      // this.checkTipsShow = false;
      // this.docheck();
    },
    lastPageOnClick() {
      if (this.page > 1) {
        this.page--;
        this.getHotList();
      }
    },
    nextPageOnClick() {
      if (this.page < this.TotalPages) {
        this.page++;
        this.getHotList();
      }
    },
    // 导入
    async handleImportExcel() {
      const arrayBuffer = await selectFileAsArrayBuffer({
        accept: [".xls", ".xlsx"],
        multiple: false,
      });
      if (!arrayBuffer) return;
      const excelTable = excel2Records({
        data: arrayBuffer,
        // 按顺序设置表头
        header: ["key"],
      });
      this.codevalue = Object.values(excelTable)
        .map((item) => item["key"])
        .join("\r\n");
    },
    exportExcelOnClick() {
      if (!this.parseCheckvluered.length) {
        this.$message.error("null");
        return false;
      }
      const exc_data = [Object.keys(this.parseCheckvluered[0])];
      this.parseCheckvluered.forEach((item) => {
        exc_data.push(Object.values(item));
      });
      if (this.isMinDevice) {
        try {
          this.$copyText(document.getElementById("copyText").innerText);
          this.$message.success("success");
          return false;
        } catch (error) {
          this.$message.error(error);
          return false;
        }
      }
      exportExcel(`table${Date.now()}`, exc_data);
    },
    modelExport() {
      const array = [
        ["key"],
        ["36H8N-87FTD-43DF2-TH9V8-DGPJP"],
        ["6BGNX-PP42W-Y9TB6-JTD7K-C7K7C"],
      ];
      exportExcel(`KeyTemplate`, array);
    },
    beforedocheck() {
      if (this.tipdata) {
        this.docheck();
      } else {
        this.checkTipsShow = true;
      }
      // if (!this.$globelData.isShowTip) {
      //   this.docheck();
      //   return false;
      // }
      // this.checkTipsShow = true;
    },
    async docheck() {
      if (this.codevalue != "") {
        if (this.loading) {
          this.$message.error("Testing in progress, please wait");
          return false;
        }
        if (this.Timer) {
          clearInterval(this.Timer);
        }
        this.checkvluered = [];
        this.loading = true;
        let num = 1;
        this.loadingText = `loading${num}%`;
        this.Timer = setInterval(() => {
          if (num < 99) {
            num++;
            this.loadingText = `loading${num}%`;
          }
        }, 2000);
        const res = await checkKeyApi(this.codevalue, this.selectItemType);
        if (res.code == 1) {
          this.loading = false;
          setTimeout(() => {
            num = 1;
            this.loadingText = `loading1%`;
          }, 300);
          if (res.data?.length) {
            this.checkvluered = res.data.map((item) => {
              return {
                Key:
                  this.selectItemType == "pid"
                    ? item.keyname_with_dash
                    : item.keyname,
                Description: item.prd,
                Version: this.selectItemType == "pid" ? item.sub : item.sub,
                status:
                  this.selectItemType == "pid" ? item.errorcode : item.status,
              };
            });
            this.$message.success(res.msg);
          } else {
            this.checkvluered = [];
            this.$message.error("check error");
          }
        }
      }
    },
    async getHotList() {
      const params = {
        flag: "hot",
        page: this.page,
      };
      const res = await getHotListApi(params);
      this.goodsList = res.data.Items;
      this.TotalPages = res.data.TotalPages;
    },
  },
};
</script>

<style lang="scss">
.keysbox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0.9rem 2.23rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
  .center {
    width: 100%;
    margin: 0 auto;

    .commercial {
      width: 100%;
      // height: 0.8rem;
      // background-color: #dfdfdf;
      // margin-top: 0.9rem;
      margin-bottom: 0.35rem;
    }
    .mb-commercial {
      margin-bottom: 1.0241rem;
    }
    .commercial-text {
      font-size: 0.2rem;
    }
    .mb-commercial-text {
      color: rgba(137, 137, 137, 1);
      font-size: 0.6144rem;
      text-align: center;
      margin-bottom: 1.0241rem;
    }

    .main {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .box {
        width: 7.24rem;
        // height: 8.5rem;
        .box-title {
          margin-bottom: 0.25rem;

          .title {
            width: 2.2rem;
            height: 0.5rem;
            border: 0.01rem solid #e2e2e2;
            box-shadow: 0 0 0.04rem 0 rgba($color: #000000, $alpha: 0.2);
            font-size: 0.18rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            text-align: center;
            line-height: 0.5rem;
            margin-right: 0.25rem;
            // margin-bottom: 0.25rem;
          }
        }

        .infobox {
          width: 100%;
          height: 6.38rem;
          border: 0.01rem solid #eee;
          padding: 0.2rem;
          box-sizing: border-box;
          box-shadow: #eee 0 0 0rem 0.02rem;
          border-radius: 0.06rem;
          resize: none;
          // text-indent: 0.2rem;
          padding-top: 0.2rem;
          .infobox-box {
            width: 100%;
            height: 100%;
            border: 0;
            resize: none;
          }
        }

        .showinfobox {
          overflow-y: auto;
          z-index: 1 !important;
        }

        .l_btnbox {
          width: 100%;
          height: 0.9rem;
          display: flex;
          align-items: center;

          .btn1 {
            width: 1.6rem;
            height: 0.4rem;
            background: #ffb100;
            border-radius: 0.04rem;
            text-align: center;
            line-height: 0.4rem;
            font-size: 0.18rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 0.25rem;
          }
          .btn3 {
            position: absolute;
            top: 0.55rem;
            font-size: 0.16rem;
            width: 6rem;
            color: #278bff;
          }

          .btn2 {
            width: 2.04rem;
            height: 0.4rem;
            background: #ffb100;
            border-radius: 0.04rem;
            text-align: center;
            line-height: 0.4rem;
            font-size: 0.18rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }

        .r_btnbox {
          width: 100%;
          height: 0.9rem;
          display: flex;
          justify-content: end;
          align-items: center;

          .btn3 {
            background: #ffb100;
            border-radius: 0.04rem;

            .select-box {
              border: 0.01rem solid #ffb100;
              .input-value {
                color: #fff;
              }

              .float-box {
                .float-box-item {
                  background: rgb(240, 229, 204);
                  color: #000;
                }

                .float-box-item:hover {
                  background: #ffb100;
                  color: #000;
                }
              }

              .row {
                border-color: rgb(255, 255, 255) transparent transparent
                  transparent;
              }
            }
          }

          .btn4 {
            width: 1.56rem;
            height: 0.4rem;
            background: #ffb100;
            border-radius: 0.04rem;
            text-align: center;
            font-size: 0.18rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-left: 0.25rem;
          }
          .mb-btn4 {
            width: 3.533rem;
            height: 1.0753rem;
            font-size: 0.7168rem;
          }
        }
        .mb-r_btnbox {
          margin-bottom: 1.0241rem;
        }
      }
    }
    .mb-main {
      display: block;
      .box1 {
        margin-bottom: 1.0241rem;
      }
      .box {
        width: 100%;
        // height: 14.6441rem;
        .box-title {
          margin-bottom: 1.0241rem;
          .title {
            width: unset;
            height: unset;
            padding: 0.3072rem 0.512rem;
            box-sizing: border-box;
            font-size: 0.7144rem;
            margin-bottom: 0;
            margin-right: 0.6144rem;
            width: fit-content;
          }
        }
        .infobox {
          height: 14.6441rem;
          font-size: 0.7168rem;
          margin-bottom: 0.6144rem;
          box-shadow: rgba(173, 173, 173, 1) 0 0 0.3rem 0.02rem;
          border-radius: 0.2rem;
        }
        .l_btnbox {
          .btn1 {
            width: unset;
            height: unset;
            padding: 0.1024rem 0.8193rem;
            box-sizing: border-box;
            font-size: 0.6144rem;
            line-height: unset;
            line-height: unset;
            margin-right: 0;
          }
          .btn2 {
            width: unset;
            height: unset;
            padding: 0.1024rem 0.8193rem;
            box-sizing: border-box;
            font-size: 0.6144rem;
            line-height: unset;

            // margin-bottom: 1.0241rem;
          }
        }
      }
    }
  }
}
.mb-keysbox {
  padding: 0.6144rem 1.0241rem;
}
</style>
